import React from 'react';
import {
  Box,
  HStack,
  useRadioGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
  PopoverArrow,
  useMediaQuery
} from '@chakra-ui/react';

const ColorPicker = ({
  colors,
  selectedColor,
  setSelectedColor,
  isCenter,
  theme
}) => {
  // Check if mobile (assuming 600px is max)
  // If mobile, popover will show on click - on desktop it show on hover
  const [isLowerThan600] = useMediaQuery('(max-width: 600px)');

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'coloroptions',
    defaultValue: selectedColor,
    onChange: setSelectedColor
  });

  const group = getRootProps();

  return (
    <HStack
      {...group}
      flexFlow="wrap"
      spacing={0}
      mx={{
        base: isCenter ? 10 : 0,
        sm: isCenter ? 40 : 0,
        md: isCenter ? 40 : 0
      }}
      justifyContent={isCenter ? 'center' : 'flex-start'}
      w={{ md: isCenter ? '300px' : 'full' }}
    >
      {colors
        ? React.Children.toArray(
            colors.map((color) => {
              return (
                <Popover
                  placement="top"
                  trigger={isLowerThan600 ? 'click' : 'hover'}
                >
                  {/* additional element */}
                  <Box
                    display="inline-block"
                    textAlign="center"
                    alignItems="center"
                  >
                    <PopoverTrigger>
                      <Box
                        as="span"
                        margin="0 auto"
                        display="table"
                        background={color.hex}
                        width={7}
                        height={7}
                        mr={2}
                        mb={2}
                        border={'2px solid'}
                        borderColor={
                          // If there are duplicate colors, multiple colors will be highligted
                          selectedColor == color.hex
                            ? theme === 'dark'
                              ? '#ffffff'
                              : '#000000'
                            : theme === 'dark'
                            ? '#555555'
                            : '#dddddd'
                        }
                        // boxShadow="0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);"
                        borderRadius="50%"
                        cursor="pointer"
                        onClick={() => {
                          setSelectedColor(color.hex);
                        }}
                      />
                    </PopoverTrigger>
                  </Box>
                  <PopoverContent
                    bg="gray.400"
                    w="fit-content"
                    p={2}
                    border="none"
                  >
                    <Text color="white">{color.label}</Text>
                    <PopoverArrow bg="gray.400" />
                  </PopoverContent>
                </Popover>
              );
            })
          )
        : null}
    </HStack>
  );
};

export default ColorPicker;
