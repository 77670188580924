import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Tooltip,
  useRadio,
  useRadioGroup,
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  Text,
  PopoverArrow,
  useMediaQuery
} from '@chakra-ui/react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { RepeatIcon } from '@chakra-ui/icons';

const StandardColorPicker = ({ color, setColor }) => {
  return (
    <Box width="200px">
      <HexColorPicker
        style={{ width: 'auto', height: '150px' }}
        color={color}
        onChange={setColor}
      />
      <HStack mt={2}>
        <Text fontWeight="semibold">HEX</Text>
        <HexColorInput
          style={{
            display: 'block',
            boxSizing: 'border-box',
            width: '100%',
            background: '#eee',
            textTransform: 'uppercase',
            textAlign: 'center',
            padding: '2px',
            border: '1px solid #ddd',
            borderRadius: '4px'
          }}
          color={color}
          onChange={setColor}
        />
        <Tooltip label="Reset color" aria-label="Reset color" fontSize="sm">
          <IconButton
            colorScheme="gray"
            aria-label="Reset"
            icon={<RepeatIcon />}
            size="sm"
            onClick={() => setColor('#000000')}
          />
        </Tooltip>
      </HStack>
    </Box>
  );
};

export default StandardColorPicker;
