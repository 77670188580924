export const EXAMPLE_PATH = 'blog-starter';
export const CMS_NAME = 'Markdown';
export const HOME_OG_IMAGE_URL = 'https://fontvisual.com/fontvisual.png';

export const ShowcaseLayouts = {
  DEFAULT: { value: 'Default', key: 'default' },
  LAYOUTB: { value: 'Minimal', key: 'layoutB' },
  LAYOUTC: { value: 'Centered', key: 'layoutC' },
  LAYOUTD: { value: 'Modern', key: 'layoutD' }
};

export const getShowcaseLayout = (key) => {
  for (let layout in ShowcaseLayouts) {
    if (ShowcaseLayouts[layout].key == key) {
      return ShowcaseLayouts[layout].value;
    }
  }
};
