import { Image } from '@chakra-ui/react';
import { useImage } from 'hooks/useImage';

const ImageView = ({ imageId, ...rest }) => {
  const { data: image } = useImage(imageId);
  // console.log('image', image);

  return image ? <Image src={image.url} width="100%" {...rest} /> : <></>;
};

export default ImageView;
