import React, { useState, useEffect, useRef } from 'react';
import {
  Center,
  Heading,
  Text,
  VStack,
  HStack,
  Link,
  Box,
  Input,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Container,
  Flex,
  useRadioGroup,
  useRadio,
  Image,
  Textarea
} from '@chakra-ui/react';
import NextImage from 'next/image';
// import { CirclePicker } from 'react-color';
import ImageView from '@/components/ImageView';
import fontvisual from '../../../public/fontvisual.png';
import ColorPicker from '@/components/ColorPicker';
import StandardColorPicker from '@/components/StandardColorPicker';

const ShowcaseCompany = ({ logoImageId, logoImage, website }) => {
  return logoImageId ? (
    <Center mt={8} mx={4}>
      <Box w="150px" h="150px">
        <Link
          href={
            website
              ? website.includes('http')
                ? website
                : `https://${website}`
              : null
          }
          isExternal
        >
          <ImageView imageId={logoImageId} />
        </Link>
      </Box>
    </Center>
  ) : logoImage ? (
    <Center mt={8} mx={4}>
      <Box w="150px" h="150px">
        <Link
          href={
            website
              ? website.includes('http')
                ? website
                : `https://${website}`
              : null
          }
          isExternal
        >
          <Image src={logoImage} width="100%" />
        </Link>
      </Box>
    </Center>
  ) : null;
};

const ShowcaseHeader = ({ name, titleFont, titleFontSize, theme }) => {
  return (
    <Center mt={8} mx={4}>
      {titleFont ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @font-face { font-family: '${titleFont.font_family_alias}'; src: url('${titleFont.url}');
            `
          }}
        />
      ) : null}
      <Heading
        as={'h1'}
        alignItems="center"
        justifyContent="center"
        fontSize={titleFontSize}
        fontWeight={'normal'}
        fontFamily={titleFont ? titleFont.font_family_alias : null}
        textAlign="center"
        maxW={{ base: 'full', md: '800px' }}
        whiteSpace="pre-wrap"
        color={theme === 'dark' ? 'white' : 'inherit'}
      >
        {name}
      </Heading>
    </Center>
  );
};
const ShowcaseMain = (props) => {
  // console.log('layoutA', props.fontList);
  const defaultText = props.text ? props.text : 'Type something here';
  const [text, setText] = useState(defaultText);
  const [textLines, setTextLines] = useState(1);
  const [fontSize, setFontSize] = useState(
    props.textSize ? props.textSize : 30
  );
  const [color, setSelectedColor] = useState(
    props.theme === 'dark' ? '#ffffff' : '#000000'
  );
  const [selectedTextCase, setSelectedTextCase] = useState(
    props.onlyUppercase ? 'AA' : 'Reset'
  );

  // *** TEXTCASE

  // 1. Create a component that consumes the `useRadio` hook
  function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderColor="gray.400"
          borderRadius="md"
          boxShadow="md"
          color={props.theme === 'dark' ? 'white' : null}
          _checked={{
            bg: props.theme === 'dark' ? 'white' : 'gray.400',
            color: props.theme === 'dark' ? 'black' : 'white',
            borderColor: 'gray.400'
          }}
          px={1}
          py={1}
          fontSize="sm"
        >
          {props.children}
        </Box>
      </Box>
    );
  }

  // Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
  const TextcaseOptions = ({ theme }) => {
    const options = ['Reset', 'aa', 'Aa', 'AA'];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: 'radiooptions',
      defaultValue: selectedTextCase,
      onChange: setSelectedTextCase
    });

    const group = getRootProps();

    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value, theme });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
    );
  };

  //todo
  const textCase = () => {
    switch (selectedTextCase) {
      case 'Reset':
        return 'none';
      case 'aa':
        return 'lowercase';
      case 'Aa':
        return 'capitalize';
      case 'AA':
        return 'uppercase';
      default:
        return 'none';
    }
  };

  function handleChange(event) {
    if (event.target.value == '') {
      setText(defaultText);
      textareaRef.current.style.height = '44px';
    } else {
      setText(event.target.value);
      textareaRef.current.style.height = '0px';
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    }
  }

  function handleSliderChange(val) {
    setFontSize(val);
  }

  function handleChangeComplete(color) {
    setSelectedColor(color.hex);
  }

  useEffect(() => {
    setFontSize(fontSize); // set new value when value gets changed, even when first render
  }, [fontSize]);

  useEffect(() => {
    const lines = text ? text.split('\n') : null;
    setTextLines(lines ? lines.length : 1);
  }, [text]);

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  useEffect(() => {
    setFontSize(props.textSize);
  }, [props.textSize]);

  useEffect(() => {
    setSelectedColor(props.theme === 'dark' ? '#ffffff' : '#000000');
  }, [props.theme]);

  useEffect(() => {
    !props.displayTextCase && !props.onlyUppercase
      ? setSelectedTextCase('Reset')
      : props.onlyUppercase
      ? setSelectedTextCase('AA')
      : props.text.toLowerCase() === 'type something here...'
      ? setSelectedTextCase('Reset')
      : null;
  }, [props.displayTextCase, props.onlyUppercase]);

  const textareaRef = useRef();

  return (
    <Box m={{ md: '50' }} my={{ md: 8 }} px={{ md: 8 }} mb={16}>
      <Center>
        <Textarea
          ref={textareaRef}
          p={2}
          m={4}
          backgroundColor="transparent"
          maxWidth={{ base: 'full', md: '400px' }}
          borderRadius="md"
          placeholder={props.text ? props.text : 'Type something here'}
          variant="outline"
          // border="2px"
          borderColor="gray.500"
          focusBorderColor="primary.400"
          // value={props.text ? props.text : 'Type something here'}
          onChange={handleChange}
          size={'lg'}
          textTransform={textCase}
          whiteSpace="pre-wrap"
          resize="none"
          minHeight={textLines == 1 ? '44px' : null}
          height={textLines == 1 ? '44px' : textLines * 30 + 'px'}
          color={props.theme === 'dark' ? 'white' : null}
        />
        {/* <Input
          p={2}
          m={4}
          backgroundColor="gray.100"
          maxWidth={{ base: 'full', md: '400px' }}
          borderRadius="md"
          placeholder={props.text ? props.text : 'Type something here'}
          variant="outline"
          // border="2px"
          borderColor="gray.500"
          focusBorderColor="primary.400"
          // value={props.text ? props.text : 'Type something here'}
          onChange={handleChange}
          size={'lg'}
          textTransform={
            props.text === 'Type something here...' ? 'none' : textCase
          }
        /> */}
      </Center>

      {props.displayTextSize ? (
        <Center mx="4">
          <Tooltip label="Font size" aria-label="Font size">
            <Text color={props.theme === 'dark' ? 'white' : 'inherit'}>
              {fontSize}px
            </Text>
          </Tooltip>
          <Slider
            aria-label="font size"
            focusThumbOnChange={false}
            size="lg"
            width={{ base: 'full', md: '300px' }}
            ml={4}
            defaultValue={fontSize}
            value={fontSize}
            colorScheme="darkpastelgreen"
            min={8}
            max={300}
            step={1}
            onChange={handleSliderChange}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={4}>
              <Box />
            </SliderThumb>
          </Slider>
        </Center>
      ) : null}

      {props.displayTextCase && !props.onlyUppercase && (
        <Center>
          <TextcaseOptions theme={props.theme} />
        </Center>
      )}

      {props.colors && props.colorOption === 'Custom' ? (
        <Center mt={4}>
          <ColorPicker
            colors={props.colors}
            // colors={
            //   props.colors ? props.colors.map((color) => color.hex) : null
            // }
            selectedColor={color}
            setSelectedColor={setSelectedColor}
            isCenter={true}
            theme={props.theme}
          />
        </Center>
      ) : null}

      {props.colorOption === 'Standard' ? (
        <Center mt={4}>
          <StandardColorPicker color={color} setColor={setSelectedColor} />
        </Center>
      ) : null}

      <Flex
        flexFlow="row wrap"
        // justifyContent="space-around"
        padding={{ base: '5', md: '10' }}
      >
        {props.fontList
          ? props.fontList.length > 0
            ? props.fontList.map((font) => (
                <FontItem
                  type="custom"
                  key={font.font_family_alias}
                  text={text}
                  textCase={textCase}
                  font={font}
                  fontSize={fontSize + 'px'}
                  image={props.image}
                  imageId={props.imageId}
                  color={color}
                  theme={props.theme}
                />
              ))
            : 'No Fonts Available'
          : ''}
      </Flex>
    </Box>
  );
};

const FontItem = React.memo((props) => {
  return (
    <>
      <Box
        display={props.font.selected ? 'block' : 'none'}
        // cursor="pointer"
        // maxWidth="350px"
        flexGrow="1"
        flexShrink="1"
        borderRadius="md"
        boxShadow={props.theme === 'dark' ? '0 13px 25px -12px #dddddd' : '2xl'}
        border={props.theme === 'dark' ? '1px' : 'none'}
        borderColor={props.theme === 'dark' ? 'gray.400' : 'white'}
        m={3}
        background={props.theme === 'dark' ? 'black' : 'white'}
        padding={3}
        variant="outline"
        // display={props.font.selected ? 'block' : 'none'}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
            @font-face { font-family: '${props.font.font_family_alias}'; src: url('${props.font.url}');
            `
          }}
        />

        <Text fontSize="md" textAlign="left" color="gray">
          {props.type === 'custom'
            ? props.font.display_name
              ? props.font.display_name
              : props.font.font_family
            : props.font.font_family}
        </Text>
        {props.image ? (
          <Container textAlign="center" position="relative" maxWidth="300px">
            <Image
              // boxSize="600px"
              width="100%"
              src={props.image}
            />
            <Text
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily={props.font.font_family_alias}
              fontSize={props.fontSize}
              textTransform={
                props.font.text_case_support !== null &&
                props.font.text_case_support !== ''
                  ? props.font.text_case_support
                  : props.textCase
              }
              color={props.color ? props.color : '#000000'}
              whiteSpace="pre-wrap"
            >
              {props.text}
            </Text>
          </Container>
        ) : props.imageId ? (
          <Container textAlign="center" position="relative" maxWidth="300px">
            <ImageView imageId={props.imageId} />
            <Text
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontFamily={props.font.font_family_alias}
              fontSize={props.fontSize}
              textTransform={
                props.font.text_case_support !== null &&
                props.font.text_case_support !== ''
                  ? props.font.text_case_support
                  : props.textCase
              }
              color={props.color ? props.color : '#000000'}
              whiteSpace="pre-wrap"
            >
              {props.text}
            </Text>
          </Container>
        ) : (
          <Box
            textAlign="center"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            fontFamily={props.font.font_family_alias}
            fontSize={props.fontSize}
            textTransform={
              props.font.text_case_support !== null &&
              props.font.text_case_support !== ''
                ? props.font.text_case_support
                : props.textCase
            }
            color={props.color ? props.color : '#000000'}
            whiteSpace="pre-wrap"
            p={2}
          >
            {props.text}
          </Box>
        )}
      </Box>
    </>
  );
});

const ShowcaseFooter = () => {
  return (
    <HStack alignItems="center" justifyContent="center" mt={16} mb={8}>
      <Text fontSize="sm" color="gray.500" pb={2}>
        Powered by{' '}
      </Text>
      <a
        href="https://fontvisual.com?ref=watermark"
        target="_blank"
        rel="noreferrer"
      >
        <NextImage
          src={fontvisual}
          alt="FontVisual"
          width="78px"
          height="18px"
        />
      </a>
    </HStack>
  );
};

function ShowcaseLayoutA({
  logoImageId,
  logoImage,
  website,
  displayTitle,
  name,
  titleFont,
  titleFontSize,
  text,
  textSize,
  fontList,
  image,
  imageId,
  branding,
  colors,
  colorOption,
  displayTextCase,
  onlyUppercase,
  theme,
  displayTextSize
}) {
  return (
    <Flex
      background={theme === 'dark' ? 'black' : null}
      flexDir="column"
      // pb="8"
      // maxH="2000px"
      height="100%"
      // overflow="auto"
      // sx={{
      //   '&::-webkit-scrollbar': {
      //     width: '15px'
      //     // borderRadius: '2px'
      //   },
      //   '&::-webkit-scrollbar-track': {
      //     background: `#f1f1f1`
      //   },
      //   '&::-webkit-scrollbar-thumb': {
      //     background: `#c1c1c1`,
      //     borderRadius: '24px'
      //   },
      //   '&::-webkit-scrollbar-thumb:hover': {
      //     background: `#888`
      //   }
      // }}
      // mx={fontList && fontList.length < 3 ? '200' : 'auto'}
    >
      <ShowcaseCompany
        logoImageId={logoImageId}
        logoImage={logoImage}
        website={website}
      />
      {displayTitle ? (
        <ShowcaseHeader
          name={name}
          titleFont={titleFont}
          titleFontSize={titleFontSize + 'px'}
          theme={theme}
        />
      ) : null}
      <ShowcaseMain
        text={text}
        textSize={textSize}
        fontList={fontList}
        image={image}
        imageId={imageId}
        colors={colors}
        colorOption={colorOption}
        displayTextCase={displayTextCase}
        onlyUppercase={onlyUppercase}
        theme={theme}
        displayTextSize={displayTextSize}
      />
      {branding && branding === 'remove' ? null : <ShowcaseFooter />}
    </Flex>
  );
}

export default ShowcaseLayoutA;
