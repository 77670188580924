import { useQuery } from 'react-query';
import { supabase } from '@/utils/supabase-client';

const fetchImageById = async (id) => {
  const user = supabase.auth.user();
  const { data, error } = await supabase
    .from('images')
    .select('id,url,filename,type,showcase_ids')
    .eq('user_id', user.id)
    .eq('id', id)
    .single();

  if (error) {
    // console.log('fetchImageById error: ' + error.message);
    throw error;
  }

  return data;
};

const useImage = (id) => {
  return useQuery(['images', id], () => fetchImageById(id));
};

export { useImage, fetchImageById };
